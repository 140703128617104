@import "variables";
@import "mixins";

/* -------------
  パンくずリスト
------------- */

.body {
  // PCでは最上部、SPでは最下部に出す
  @include pc() {
    order: 0;
  }
  @include sp() {
    order: 1;
  }

  ul {
    @include pc() {
      margin: 18px 0 15px;
    }
    @include sp() {
      white-space: nowrap;
      line-height: 1.5;
      overflow-x: auto;
      margin-bottom: 16px;
    }
  }
  li {
    font-size: 12px;
    line-height: 1.2;
    display: inline-block;
    & + li {
      margin-left: 5px;
      &:before {
        content: ">";
        margin-right: 6px;
        color: $blue;
      }
    }

    a {
      text-decoration: underline;
    }

    &.isWhite {
      color: $white;

      a {
        color: $white;
      }

      & + li:before {
        color: $white;
      }
    }
  }
}
