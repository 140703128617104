@import "variables";
@import "mixins";

.background {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-image: url("/static/images/top-images/background-room.png");
  background-size: cover;
  background-position: 50%;
  position: relative;
  padding-bottom: 50px;

  @include pc {
    padding-bottom: 20px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000033;
  }
}

.container {
  display: flex;
  max-width: $pc-width;
  margin: auto;
  padding: 0 0 54px;
  gap: 32px;
  z-index: 1;

  @include sp {
    display: block;
    margin: 0;
    padding: 0 18px;
  }

  .isFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .catchText {
    display: block;
    margin: 36px 0 14px;
    font-size: 20px;
    font-weight: 600;
    color: $white;

    @include sp {
      font-size: 13px;
    }

    .prefecture {
      font-size: 28px;

      @include sp {
        font-size: 18px;
      }
    }
  }

  .backButton {
    position: relative;
    margin-top: 28px;
    font-size: 11px;
    color: $white;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: -8px;
      height: 7px;
      width: 7px;
      margin: auto;
      -webkit-transform: rotate(225deg);
      transform: rotate(225deg);
      border-top: 1.5px solid $white;
      border-right: 1.5px solid $white;
    }
  }

  .searchLinks {
    display: flex;
    gap: 16px;

    .searchLink {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 260px;
      gap: 8px;
      background: $white;
      border: 1px solid $lightGray;
      box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.02);
      border-radius: 10px;
      height: 80px;
      text-align: center;
      cursor: pointer;

      @include sp() {
        flex-direction: column;
        min-width: auto;
        width: 48%;
        height: 90px;
        padding: 15px;
        background: $white;
        border: 1px solid $lightGray;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.07);
        border-radius: 5px;
      }

      .text {
        font-size: 16px;
        font-weight: 600;

        @include sp {
          font-size: 14px;
        }

        .isPcBigText {
          @include pc {
            font-size: 20;
          }
        }
      }
    }
  }

  .searchKeyword {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 18px;

    @include sp {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      margin-top: 22px;
    }

    .label {
      flex-shrink: 0;
      font-size: 18px;
      font-weight: 600;

      @include sp {
        font-size: 14px;
      }
    }
  }

  .pastCriteria {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 21px;
    margin-top: 26px;
    border: 3px solid $blue;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgb(35, 145, 220, 0.2);
    cursor: pointer;
    background-color: $white;

    @include sp() {
      border: 2px solid $blue;
      height: 74px;
      padding: 15px;
    }

    p {
      color: $blue;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      letter-spacing: 0.8px;
      text-align: left;

      @include sp() {
        font-size: 14px;
        line-height: 22px;
      }
    }

    span {
      margin: 0 !important;
    }

    .small {
      font-size: 13px;
      line-height: 20px;
      text-align: left;

      @include sp() {
        font-size: 12px;
        line-height: 20px;
      }
    }

    .textOverflow {
      max-width: 450px;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .newArrival:before {
    content: "";
    display: block;
    position: absolute;
    top: 11px;
    right: 11px;
    margin-right: 0;
    width: 9px;
    height: 9px;
    flex-shrink: 0;
    border-radius: 9px;
    background: $lightGreen2;
  }
}
.carouselItem {
  margin-bottom: 30px;

  @include pc() {
    width: 1140px;
    margin: -30px auto 30px auto;
  }
}
